var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay,"opacity":"0.17","blur":"1rem","rounded":"md","variant":"secondary"}},[_c('div',[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between px-1 py-1"},[_c('h4',{staticClass:"mb-0 font-weight-bold align-center text-truncate"},[_vm._v(" Add Details ")])]),_c('div',{staticClass:"px-2"},[_c('validation-observer',{ref:"generatePolicy",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.generatePolicy)}}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Policy Name"}},[_c('template',{slot:"label"},[_vm._v(" Policy Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Policy Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"name":"policy_name","placeholder":"Policy Name"},model:{value:(_vm.formData['policygroup_name']),callback:function ($$v) {_vm.$set(_vm.formData, 'policygroup_name', $$v)},expression:"formData['policygroup_name']"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1),_vm._l((_vm.formData.variable_conf),function(variable,index){return _c('b-col',{key:variable.variable_id},[_c('b-form-group',{attrs:{"label":variable.variable_label}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(variable.variable_label)+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":variable.variable_label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.variableDetails[index].variable_input_type==='TEXT')?_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"name":"variable_value","placeholder":_vm.variableDetails[index].variable_helper_text},model:{value:(variable.variable_value),callback:function ($$v) {_vm.$set(variable, "variable_value", $$v)},expression:"variable.variable_value"}}):_vm._e(),(_vm.variableDetails[index].variable_input_type==='SINGLE_CHOICE'||_vm.variableDetails[index].variable_input_type==='MULTIPLE_CHOICE')?_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"name":"variable_value","placeholder":_vm.variableDetails[index].variable_helper_text,"options":JSON.parse(_vm.variableDetails[index].variable_input_options).map(function (item){
                    return {
                      value:item.value,
                      text:item.label
                    }
                  }),"multiple":_vm.variableDetails[index].variable_input_type==='MULTIPLE_CHOICE'},model:{value:(variable.variable_value),callback:function ($$v) {_vm.$set(variable, "variable_value", $$v)},expression:"variable.variable_value"}}):_vm._e(),(_vm.variableDetails[index].variable_input_type==='RADIO_BUTTON')?_c('b-form-radio-group',{attrs:{"state":errors.length > 0 ? false : null,"name":"variable_value","placeholder":_vm.variableDetails[index].variable_helper_text,"options":JSON.parse(_vm.variableDetails[index].variable_input_options).map(function (item){
                    return {
                      value:item.value,
                      text:item.label
                    }
                  })},model:{value:(variable.variable_value),callback:function ($$v) {_vm.$set(variable, "variable_value", $$v)},expression:"variable.variable_value"}}):_vm._e(),(_vm.variableDetails[index].variable_input_type==='TEXT_AREA')?_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"name":"variable_value","placeholder":_vm.variableDetails[index].variable_helper_text},model:{value:(variable.variable_value),callback:function ($$v) {_vm.$set(variable, "variable_value", $$v)},expression:"variable.variable_value"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)],1)}),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.showOverlay},on:{"click":_vm.handleSaveAsDraft}},[_c('span',[(_vm.showOverlay)?_c('b-spinner',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"variant":"secondary"}}):_vm._e(),_vm._v(" Save As Draft")],1)]),_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid||_vm.showOverlay}},[_c('span',[(_vm.showOverlay)?_c('b-spinner',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"variant":"secondary"}}):_vm._e(),_vm._v(" Generate Policy ")],1)])],1)],2)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }