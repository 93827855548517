<template>
  <b-overlay
  :show="showOverlay"
  opacity="0.17"
  blur="1rem"
  rounded="md"
  variant="secondary"
  >
  <div>
    <div
      class="w-100 d-flex align-items-center justify-content-between px-1 py-1"
    >
      <h4
        class="mb-0 font-weight-bold align-center text-truncate"
      >
        Add Details
      </h4>
      <!-- <b-button size="md" variant="primary" @click="$router.back()">
        Back
      </b-button> -->
    </div>
    <div class='px-2'>
      <validation-observer ref="generatePolicy" v-slot="{ handleSubmit,invalid }">
        <b-form class="mt-1" @submit.prevent="handleSubmit(generatePolicy)">
          <!-- Title -->
           <!-- {{ formData }} -->
          <b-col>
            <b-form-group label="Policy Name">
              <template slot="label">
                Policy Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Policy Name"
                rules="required"
              >
                <b-form-input
                  
                  v-model="formData['policygroup_name']"
                  
                  :state="errors.length > 0 ? false : null"
                  name="policy_name"
                  placeholder="Policy Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-for="(variable,index) in formData.variable_conf" :key="variable.variable_id"> 
            <b-form-group :label="variable.variable_label">
              <template slot="label">
                {{ variable.variable_label }} <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                :name="variable.variable_label"
                rules="required"
              >
                <b-form-input v-if="variableDetails[index].variable_input_type==='TEXT'"
                  
                  v-model="variable.variable_value"
                  :state="errors.length > 0 ? false : null"
                  name="variable_value"
                  :placeholder="variableDetails[index].variable_helper_text"
                />
                <b-form-select v-if="variableDetails[index].variable_input_type==='SINGLE_CHOICE'||variableDetails[index].variable_input_type==='MULTIPLE_CHOICE'"
                  
                  v-model="variable.variable_value"
                  :state="errors.length > 0 ? false : null"
                  name="variable_value"
                  :placeholder="variableDetails[index].variable_helper_text"
                  :options="JSON.parse(variableDetails[index].variable_input_options).map(item=>{
                    return {
                      value:item.value,
                      text:item.label
                    }
                  })"
                  :multiple="variableDetails[index].variable_input_type==='MULTIPLE_CHOICE'"
                />

                <b-form-radio-group v-if="variableDetails[index].variable_input_type==='RADIO_BUTTON'"
                  
                  v-model="variable.variable_value"
                  :state="errors.length > 0 ? false : null"
                  name="variable_value"
                  :placeholder="variableDetails[index].variable_helper_text"
                  :options="JSON.parse(variableDetails[index].variable_input_options).map(item=>{
                    return {
                      value:item.value,
                      text:item.label
                    }
                  })"
                />
                <b-form-textarea v-if="variableDetails[index].variable_input_type==='TEXT_AREA'"
                  
                  v-model="variable.variable_value"
                  :state="errors.length > 0 ? false : null"
                  name="variable_value"
                  :placeholder="variableDetails[index].variable_helper_text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          
  
  
  
          <b-col class="d-flex justify-content-end">
            <b-button
            class="mr-1"
              variant="primary"
              @click="handleSaveAsDraft"
              :disabled="showOverlay"
            >
             <span><b-spinner variant="secondary" v-if="showOverlay" style="width: 1rem; height: 1rem;"></b-spinner> Save As Draft</span> 
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid||showOverlay"
            >
            <span>
              <b-spinner v-if="showOverlay" variant="secondary" style="width: 1rem; height: 1rem;"></b-spinner>
              Generate Policy
            </span>
            </b-button>

          </b-col>
        </b-form>
      </validation-observer>
    </div>
  </div>
</b-overlay>
</template>
<script>
import { BButton, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormSelect, BFormTextarea, BOverlay, BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver,extend } from "vee-validate";
import { required } from "@validations";
import PoliciesMixins from "@/mixins/PoliciesMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default{
    props:{
        cancelAction:{
            type:Function,
            required:false,
            default:()=>{}
        },
        // variableDetails:{
        //   type:Array,
        //   required:true
        // },
        // policy:{
        //   type:Object,
        //   required:true
        // }
    },
    data(){
        return {
            formData:{
            },
            showOverlay:false
        }
    },
    computed:{
      policy(){
        return this.$store.state.policies.policy
      },
      variableDetails(){
        return this.$store.state.policies.variableDetails
      }
    },
    mixins:[PoliciesMixins,ResponseMixins],
    mounted(){
      this.load()
    },
    components:{
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BOverlay,
        BSpinner,
        ToastificationContent,
        BFormSelect,
        BFormRadioGroup,BFormTextarea
    },
    watch:{
      policy:{
        handler:function(){
          console.log('policcy',this.policy)
          // if(this.policy.group_id){
 
          // }
        },
        deep:true
      }
    },
    methods:{
      load(){
        this.setPolicyData()
      },
      handleSaveAsDraft(){
        this.formData.is_drafted=true
        this.generatePolicy() 
      },
      async setPolicyData(){
        const res=await this.getPolicy(this.policy.group_id)
        if(res.data.length){
          this.formData={group_id:this.policy.group_id,policygroup_name: this.policy?.group_name,description: this.policy?.group_description,status:res.data[0].status,variable_conf:JSON.parse(res.data[0].variable_conf),is_drafted:false}
        }
        else{
          this.formData={
                group_id: this.policy?.group_id,
                policygroup_name: this.policy?.group_name,
                status: 1,
                description: this.policy?.group_description,
                is_drafted:false,
                variable_conf:this.variableDetails?.map(item=>{
                  return {
                    variable_id:item?.variable_id,
                    variable:item?.variable_name,
                    variable_label:item?.variable_label,
                    variable_value:null,
                    variable_input_options:item?.variable_input_options,
                    variable_input_type:item?.variable_input_type,
                  }
                })
            }
        }
      },
       async generatePolicy(){
            try {
              this.showOverlay=true
              const data={...this.formData,variable_conf:JSON.stringify(this.formData.variable_conf)}

              const res=await this.generateNewPolicy(data)
              this.cancelAction()
              this.$toast({
                  component: ToastificationContent,
                  props: {
                    title:`${this.formData.is_drafted ?'Updated policy':'Added a new policy'} `,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              this.$router.back()
            } catch (error) {
              this.handleError(error)
            }finally{
              this.showOverlay=false
            }
        }
    }
}
</script>