export default{
    methods:{
      getMypolicies(param){

        return new Promise((resolve, reject) => {
          const options = {
              method: "GET",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              url: `${process.env.VUE_APP_BASEURL}/toolkit/polices/`,
              params:param
          };
          this.$http(options)
              .then(res => resolve(res))
              .catch(err => reject(err))
      })
      },
        getPolicyGroups(params){
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/toolkit/groups/`,
                    params:params
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        getPolicy(group){
          return new Promise((resolve,reject)=>{
            const options = {
              method: "GET",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              url: `${process.env.VUE_APP_BASEURL}/toolkit/drafted-policy`,
              params:{
                group_id:group
              }
          }
          this.$http(options)
              .then(res => resolve(res))
              .catch(err => reject(err))
          })
        },
        convertToFormData(obj){
            let fd = new FormData();

            for (let property in obj) {
              if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                  let nestedFormData = objectToFormData(obj[property]);
                  for (let pair of nestedFormData.entries()) {
                    fd.append(`${property}[${pair[0]}]`, pair[1]);
                  }
                } else {
                  fd.append(property, obj[property]);
                }
              }
            }
          
            return fd;
        },
        generateNewPolicy(data){
            console.log('payload',data)
            // const formdata=this.convertToFormData(data)
            return new Promise((resolve, reject) => {
                const options = {
                    method: "POST",
                    url: `${process.env.VUE_APP_BASEURL}/toolkit/genarate-policy/`,
                    data:data
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        }
    }
}